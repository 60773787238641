import { createRouter, createWebHistory } from 'vue-router';
import { isZoneAuthorised } from '@/utils';
import store from '../store';
import { appConfig as config } from '../../config';
import userHTTP from '../utils/userHttp';

const router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: '/',
    name: 'Home',
    component: () => import('@/components/views/Dashboard'),
    meta: { pageTitle: 'Dashboard', icon: 'business' },
  },
  {
    path: '/metadata',
    name: 'Metadata',
    meta: { pageTitle: 'Metadata', icon: 'find_in_page' },
    component: () => import('@/components/views/Metadata'),
    children: [
      {
        path: ':index',
        name: 'CatalogData',
        meta: { pageTitle: 'Metadata', icon: 'find_in_page' },
        component: () => import('@/components/metadata/CatalogData'),
      },
    ],
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('@/components/views/Accounts'),
    meta: { pageTitle: 'Accounts', icon: 'account_circle' },
  },
  {
    path: '/account',
    name: 'Account',
    redirect: '/account/invoices',
  },
  {
    path: '/account/:index',
    name: 'AccountChild',
    meta: { pageTitle: 'Account', icon: 'book' },
    component: () => import('@/components/views/Account'),
  },
  {
    path: '/users',
    name: 'Users',
    meta: { pageTitle: 'Users', icon: 'people' },
    component: () => import('@/components/views/Users'),
    async beforeEnter(to, from, next) {
      const { user } = store.state.users;
      if (!user || !user[config.auth0.namespace]) return;
      store.dispatch('toggleLoader', {
        showLoader: true,
        message: 'Loading users...',
      });
      const { accounts, group } = user[config.auth0.namespace];
      const users = await userHTTP.get(`users?userGroup=${group}&accounts=${accounts}`);
      store.commit('setUsers', users);
      next();
    },
  },
  {
    path: '/sites',
    name: 'Sites',
    meta: { pageTitle: 'Sites', icon: 'web_asset' },
    component: () => import('@/components/views/Sites'),
    children: [
      {
        path: 'pages',
        name: 'Pages',
        meta: { pageTitle: 'Pages', icon: 'book' },
        component: () => import('@/components/views/Pages'),
      },
    ],
  },
  {
    path: '/widgets',
    name: 'Widgets',
    meta: { pageTitle: 'Widgets', icon: 'widgets' },
    component: () => import('@/components/views/widgets/List'),
  },
  {
    path: '/widgets/:id',
    name: 'Edit',
    meta: { pageTitle: 'Edit Widget', icon: 'book' },
    component: () => import('@/components/views/widgets/Edit'),
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: { pageTitle: 'Transactions', icon: 'currency_exchange' },
    component: () => import('@/components/views/Transactions'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/views/Login'),
  }
    // { path: '*', name: '404' },
  ],
});

// check auth status
router.beforeEach((to, from, next) => {
  // redirecting to home if no page
  if (to.name === '404') next('/');
  const unauthenticatedRoutes = ['/login'];

  // user unauthenticated
  if (!store.getters.isAuthenticated) {
    if (unauthenticatedRoutes.includes(to.matched[0].path)) {
      next();
      return;
    }
    next('/login');
    return;
  }

  // user authenticated
  if (store.getters.isAuthenticated) {
    // if authenticated for zones route
    if (store.state.users.zones.includes(to.matched[0].path)) {
      if (isZoneAuthorised(to.matched[0].path, store)) {
        next();
        return;
      }
      next('/');
      return;
    }
    // if already authenticated and trying to return to /login
    if (unauthenticatedRoutes.includes(to.matched[0].path)) {
      next('/');
      return;
    }
    next();
  }
});

export default router;
